<template>
    <div class="p-0 m-0">
        <div class="d-flex flex-row align-items-center justify-content-end mb-2">
            <b-col cols="12" lg="3" md="4" sm="5" class="p-0">
                <b-button variant="primary" block @click="isAddingAddress=!isAddingAddress" :disabled="isAddingAddress">
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">Add New Address</span>
                </b-button>
            </b-col>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="!isAddingAddress" key="list">
                <div v-for="(address, index) in addresses" :key="index">
                    <profile-address-form :isNewAddress="false" :data="address" @submit-address-form="toggleOverlay"></profile-address-form>
                </div>
            </div>

            <div v-if="isAddingAddress" key="new">
                <b-overlay :show="showOverlay" variant="transparent" blur="2px" no-wrap></b-overlay>
                <profile-address-form :isNewAddress="true" :data="null" @toggle-address-form="isAddingAddress=!isAddingAddress" @submit-address-form="toggleOverlay"></profile-address-form>
            </div>
        </transition>
    </div>
</template>

<script>
    import { BCol, BButton, BOverlay } from 'bootstrap-vue'
    import ProfileAddressForm from './ProfileAddressForm.vue'
    import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'
    
    export default {
        components: {
            BCol,
            BButton,
            BOverlay,
            ProfileAddressForm,
            FeatherIcon,
        },
        created() {},
        mounted() {
            // Get a stored set of addresses from the store then iterate through them.
            this.addresses = this.$store.getters.getSampleAddressSet
        },
        data() {
            return {
                isAddingAddress: false,
                showOverlay: false,
                // Stored addresses
                addresses: []
            }
        },
        computed: {},
        methods: {
            toggleOverlay() {
                this.showOverlay = true
                setTimeout(() => {
                    this.showOverlay = false
                    if (this.isAddingAddress) {
                        this.isAddingAddress = false
                    }
                }, 2000)
            }
        }
    }
</script>