<template>
    <div id="user-profile">
        <section id="profile-info">
            <!-- Profile header -->
            <b-card class="profile-header mb-2" img-top alt="Cover photo" body-class="p-0">
                <div class="position-relative">
                </div>
            </b-card>

            <b-card no-body>
                <b-card-body>
                    <b-tabs pills align="left">
                        <b-tab active class="pt-2">
                            <template #title>
                                <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50"/>
                                <span class="d-none d-sm-inline">User Information</span>
                            </template>
                            <!-- Profile Form -->
                            <profile-user-details></profile-user-details>
                        </b-tab>

                        <b-tab class="pt-2">
                            <!-- Address Form -->
                            <template #title>
                                <feather-icon icon="MapPinIcon" size="16" class="mr-0 mr-sm-50"/>
                                <span class="d-none d-sm-inline">Addresses</span>
                            </template>
                            <profile-address></profile-address>
                        </b-tab>
                    </b-tabs>
                </b-card-body>
            </b-card>
        </section>
    </div>
</template>

<script>
    import { 
        BCard,
        BCardTitle,
        BCardHeader,
        BCardFooter,
        BCardBody,
        BCardText, 
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormText,
        BFormDatalist,
        BFormSelect,
        BTab,
        BTabs, 
    } from 'bootstrap-vue'
    import ProfileAddress from './ProfileAddress.vue'
    import ProfileUserDetails from './ProfileUserDetails.vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BCard,
            BCardTitle,
            BCardHeader,
            BCardFooter,
            BCardBody,
            BCardText,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormText,
            BFormDatalist,
            BFormSelect,
            BTab,
            BTabs,
            // Components
            ProfileAddress,
            ProfileUserDetails,
            FeatherIcon
        },
        created() {},
        mounted() {},
        data() {
            return {
                provinces: [
                    {value: null, text: 'Select a province', disabled: true}
                ],
                cities: [
                    {value: null, text: 'Select a city', disabled: true}
                ],
                barangays: [
                    {value: null, text: 'Select a barangay', disabled: true}
                ],
                // Data models
                selectedProvince: null,
                selectedCity: null,
                selectedBrgy: null,
                addrLine1: null,
                addrLine2: null,
                givenAddr: null,
                zipCode: null,
            }
        },
        computed: {},
        methods: {
            
        }
    }
</script>