<template>
    <b-form @submit.prevent>
        <b-overlay :show="showOverlay" variant="transparent" blur="2px" no-wrap></b-overlay>
        <div class="d-flex flex-row align-items-center justify-content-end mb-2">
            <b-col cols="12" lg="2" md="2" sm="3" class="p-0">
                <b-button variant="primary" block @click="disabled=!disabled" :disabled="!disabled">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Edit</span>
                </b-button>
            </b-col>
        </div>
        <b-row>
            <b-col cols="12" sm="6">
                <b-form-group label="First Name" label-for="field-fname">
                    <b-form-input :disabled="disabled" v-model="user.first_name" id="field-fname" name="field-fname" placeholder="First Name"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
                <b-form-group label="Last Name" label-for="field-lname">
                    <b-form-input :disabled="disabled" v-model="user.last_name" id="field-lname" name="field-lname" placeholder="Last Name"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
                <b-form-group label="Mobile Number" label-for="field-mobile">
                    <b-form-input :disabled="disabled" v-model="user.mobile" id="field-mobile" name="field-mobile" type="number" placeholder="Mobile Number"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
                <b-form-group label="Email Address" label-for="field-email">
                    <b-form-input :disabled="disabled" v-model="user.email" id="field-email" name="field-email" placeholder="Email Address"/>
                </b-form-group>
            </b-col>
            <transition name="fade">
                <b-col cols="12" v-if="!disabled" class="d-flex flex-row justify-content-end">
                    <b-button @click.prevent="toggleEditUserForm()" variant="flat-secondary" class="mr-1">Cancel</b-button>
                    <b-button @click.prevent="saveUserForm()" variant="success">Save</b-button>
                </b-col>
            </transition>
        </b-row>
    </b-form>
</template>

<script>
    import { 
        BCard,
        BCardHeader,
        BCardFooter,
        BCardBody,
        BCardText, 
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormText,
        BFormDatalist,
        BFormSelect,
        BOverlay,
    } from 'bootstrap-vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            BCard,
            BCardHeader,
            BCardFooter,
            BCardBody,
            BCardText,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormText,
            BFormDatalist,
            BFormSelect,
            BOverlay,
        },
        created() {
            this.user = this.$store.getters.getSampleProfile
        },
        mounted() {},
        data() {
            return {
                user: null,
                disabled: true,
                showOverlay: false,
            }
        },
        computed: {},
        methods: {
            toggleEditUserForm() {
                this.disabled = !this.disabled
            },
            saveUserForm() {
                this.showOverlay = true
                setTimeout(() => {
                    this.showOverlay = false
                    this.toggleEditUserForm()
                }, 2000)
            }
        }
    }
</script>

<style lang="scss">
</style>