<template v-if="isNewAddress">
    <!-- This address form is also used for adding new addresses. -->
    <b-form @submit.prevent>
        <!-- New Address -->
        <b-row class="mb-3" v-if="isNewAddress">
            <b-col cols="12" class="mb-2">
                <span class="font-weight-bold">Add New Address</span>
            </b-col>
            <b-col cols="12">
                <b-form-group label="Address Type" label-for="dropdown-address_type">
                    <b-form-select v-model="form.address_type" :options="addressTypes"/>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group label="House/Bldg. Address" label-for="field-given_address">
                    <b-form-input v-model="form.given_address" id="field-given_address" name="given_address" placeholder="House/Bldg. Address"/>
                </b-form-group>
            </b-col>

            <!--
            <b-col cols="12" lg="6">
                <b-form-group label="Address, Line 1" label-for="field-address_line1">
                    <b-form-input v-model="addrLine1" id="field-address_line1" name="address_line1" placeholder="Address, Line 1"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
                <b-form-group label="Address, Line 2" label-for="field-address_line2">
                    <b-form-input v-model="addrLine2" id="field-address_line2" name="address_line2" placeholder="Address, Line 2"/>
                </b-form-group>
            </b-col>
            -->

            <b-col cols="12" lg="4" md="6" sm="6">
                <b-form-group label="Province" label-for="dropdown-province">
                    <!-- <b-form-input id="dropdown-province" list="input-list" placeholder="Province"/> -->
                    <!-- <b-form-datalist id="input-list" :options="provinces"/> -->
                    <b-form-select v-model="form.province" :disabled="provinces.length <= 1" :options="provinces" @change="getAddressFragments(false)"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" lg="4">
                <b-form-group label="Municipality/City" label-for="dropdown-city">
                    <b-form-select v-model="form.city" :disabled="!form.province" :options="cities" @change="getAddressFragments(false)"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" lg="4">
                <b-form-group label="Barangay" label-for="dropdown-barangay">
                    <b-form-select v-model="form.brgy" :disabled="!form.city" :options="barangays"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" lg="4">
                <b-form-group label="ZIP Code" label-for="field-zip">
                    <b-form-input v-model="form.zip" id="field-zip" name="field-zip" placeholder="ZIP Code"/>
                </b-form-group>
            </b-col>

            <!-- Action buttons -->
            <b-col cols="12" class="d-flex flex-row justify-content-end">
                <b-button @click.prevent="toggleEnableAddress()" variant="flat-secondary" class="mr-1">Cancel</b-button>
                <b-button @click.prevent="saveAddress()" variant="success">Save</b-button>
            </b-col>
        </b-row>
        <!-- End New Address -->

        <!-- Address listing -->
        <b-row class="mb-3" v-if="!isNewAddress && data">
            <b-col cols="12" class="d-flex flex-row flex-wrap justify-content-end justify-content-sm-between align-items-center">
                <div class="text-capitalize d-flex flex-row align-items-center">
                    <!-- <feather-icon icon="HomeIcon" size="16"/> -->
                    <span class="d-none d-sm-block">{{ data.address_type }} address</span>
                </div>
                <div v-if="!isNewAddress">
                    <b-badge pill variant="success" v-if="data.active">Primary</b-badge>
                    <b-dropdown v-ripple.400 variant="flat-primary" right class="dropdown-icon-wrapper">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="18" class="align-middle"/>
                        </template>
                        <b-dropdown-item v-if="!data.active">
                            <!-- <feather-icon icon="EditIcon"/> -->
                            <span>Set as Primary Address</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleEnableAddress()">
                            <!-- <feather-icon icon="EditIcon"/> -->
                            <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item variant="danger">
                            <span>Remove</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-col>
            <b-col cols="12">
                <b-form-group label="House/Bldg. Address" label-for="field-given_address">
                    <b-form-input :readonly="data.disabled" v-model="data.given_address" id="field-given_address" name="given_address" placeholder="House/Bldg. Address"/>
                </b-form-group>
            </b-col>

            <!--
            <b-col cols="12" lg="6">
                <b-form-group label="Address, Line 1" label-for="field-address_line1">
                    <b-form-input v-model="addrLine1" id="field-address_line1" name="address_line1" placeholder="Address, Line 1"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
                <b-form-group label="Address, Line 2" label-for="field-address_line2">
                    <b-form-input v-model="addrLine2" id="field-address_line2" name="address_line2" placeholder="Address, Line 2"/>
                </b-form-group>
            </b-col>
            -->

            <b-col cols="12" lg="4" md="6" sm="6">
                <b-form-group label="Province" label-for="dropdown-province">
                    <!-- <b-form-input id="dropdown-province" list="input-list" placeholder="Province"/> -->
                    <!-- <b-form-datalist id="input-list" :options="provinces"/> -->
                    <b-form-select v-model="data.province.value" :disabled="data.disabled" :options="provinces"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" lg="4">
                <b-form-group label="Municipality/City" label-for="dropdown-city">
                    <b-form-select v-model="data.city.value" :disabled="data.disabled" :options="cities"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" lg="4">
                <b-form-group label="Barangay" label-for="dropdown-barangay">
                    <b-form-select v-model="data.brgy.value" :disabled="data.disabled" :options="barangays"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" v-if="!data.disabled" class="d-flex flex-row justify-content-end">
                <b-button @click.prevent="toggleEnableAddress()" variant="flat-secondary" class="mr-1">Cancel</b-button>
                <b-button @click.prevent="saveAddress()" variant="success">Save</b-button>
            </b-col>
        </b-row>
        <!-- End address listing -->
    </b-form>
</template>

<script>
    import { 
        BCard,
        BCardHeader,
        BCardFooter,
        BCardBody,
        BCardText, 
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormText,
        BFormDatalist,
        BFormSelect,
        BBadge,
        BDropdown,
        BDropdownItem,
    } from 'bootstrap-vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Ripple from 'vue-ripple-directive'
    import FeatherIcon from '../../../@core/components/feather-icon/FeatherIcon.vue'

    export default {
        props: {
            isNewAddress: Boolean,
            data: Object
        },
        components: {
            BCard,
            BCardHeader,
            BCardFooter,
            BCardBody,
            BCardText,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormText,
            BFormDatalist,
            BFormSelect,
            BBadge,
            BDropdown,
            BDropdownItem,
            FeatherIcon,
        },
        directives: {
            Ripple,
        },
        created() {
            if (!this.isNewAddress && this.data) {
                // Only perform this if the component is being used in address listings.
                this.getStoredAddressFragments()
            }
            else {
                this.getAddressFragments(false)
            }
        },
        mounted() {
        },
        data() {
            return {
                addressTypes: ['Home', 'Office'],
                provinces: [
                    {value: null, text: 'Select a province', disabled: true}
                ],
                cities: [
                    {value: null, text: 'Select a city', disabled: true}
                ],
                barangays: [
                    {value: null, text: 'Select a barangay', disabled: true}
                ],
                // Data models when updating values
                form: {
                    address_type: null,
                    province: null,
                    city: null,
                    brgy: null,
                    address_line1: null,
                    address_line2: null,
                    given_address: null,
                    zip: null,
                }
            }
        },
        methods: {
            constructOptions(data, list) {
                if (list.length > 1) {
                    list.splice(1, list.length - 1)
                }
                data.forEach(item => {
                    let i = {value: item.id, text: item.value, disabled: false}
                    list.push(i)
                })
            },
            getStoredAddressFragments() {
                let data = {province: this.data.province.value, city: this.data.city.value, brgy: this.data.brgy.value}
                this.getAddressFragments(true, data)
            },
            async getAddressFragments(forStored, ...params) {
                var data = null
                if (params.length > 0) {
                    data = params[0]
                }
                else {
                    data = {province: this.form.province, city: this.form.city, brgy: this.form.brgy}
                }
                this.$store.dispatch('getLocationFragment', data).then(response => {
                    let res = response.data
                    if (res.data) {
                        // Provinces
                        if (res.data.province) {
                            let prov = res.data.province
                            this.constructOptions(prov, this.provinces)

                            if (!forStored) {
                                if (this.selectedCity && this.selectedBrgy) {
                                    this.selectedCity = null
                                    this.selectedBrgy = null
                                }
                            }
                        }

                        // Cities
                        if (res.data.city) {
                            let city = res.data.city
                            this.constructOptions(city, this.cities)

                            if (!forStored) {
                                if (this.selectedBrgy) {
                                    this.selectedBrgy = null
                                }
                            }
                        }

                        // Barangays
                        if (res.data.brgy) {
                            let barangay = res.data.brgy
                            this.constructOptions(barangay, this.barangays)
                        }
                    }
                    else if (!res.result && !res.data) {
                        // Assuming that the request failed, this should fall here.
                        console.log(response)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Cannot get Locations listing right now',
                                icon: 'MapPinIcon',
                                variant: 'danger',
                                text: response.message
                            }
                        })
                    }
                }).catch(ex => {
                    console.log('Error dispatching getLocationFragment: ', ex)
                })
            },
            toggleEnableAddress() {
                if (this.isNewAddress) {
                    this.$emit('toggle-address-form')
                }
                else {
                    this.data.disabled = !this.data.disabled
                }
            },
            saveAddress() {
                if (this.isNewAddress) {
                    this.$emit('submit-address-form')
                }
                else {
                    this.toggleEnableAddress()
                }
            }
        }
    }
</script>